class PersonalizationRegistry{
    constructor(){
        this._registry = [];
    }

    /**
     * @param {Object} personalization
     * @param {Function} personalization.personalize
     */
    add(personalization){
        this._registry.push(personalization);
    }

    personalize(){
        this._registry.forEach(personalization => personalization.personalize());
    }
}

var personalizationRegistry = new PersonalizationRegistry();